'use client';

import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';

import classNames from 'classnames';

import Checked2 from '@uikit/icons/Checked2';
import useUniqueId from '@uikit/utils/hooks/useUniqueId';

export interface Props extends Omit<ComponentPropsWithoutRef<'input'>, 'type'> {
  inputClassName?: string;
  labelClassName?: string;
  indicatorClassName?: string;
}

const Checkbox = (
  { id, children, inputClassName, labelClassName, indicatorClassName, className, ...props }: Props,
  ref: Ref<HTMLInputElement>,
) => {
  const inputId = useUniqueId(id);

  return (
    <div className={classNames('checkbox w-fit', className)}>
      <input
        className={classNames('sr-only', inputClassName)}
        id={inputId}
        type="checkbox"
        ref={ref}
        {...props}
      />

      <label htmlFor={inputId} className={classNames('checkbox-label', labelClassName)}>
        <div className={classNames('checkbox-indicator', indicatorClassName)}>
          <Checked2 className="icon-2xs checkbox-icon" />
        </div>
        {children}
      </label>
    </div>
  );
};

export default forwardRef(Checkbox);

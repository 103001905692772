'use client';

import { ComponentPropsWithoutRef, useState } from 'react';

import { createSafeContext, useSafeContext } from '@uikit/utils/context';

export interface AccordionValue {
  expanded: boolean;
  toggle: () => void;
}

const AccordionContext = createSafeContext<AccordionValue>();

export const useAccordion = () => useSafeContext(AccordionContext, 'Accordion');

export type AccordionProps = ComponentPropsWithoutRef<'div'>;

export default function Accordion({ ...props }: AccordionProps) {
  const [expanded, setExpanded] = useState(false);

  const toggle = () => {
    setExpanded((oldExpanded) => !oldExpanded);
  };

  const value = {
    expanded,
    toggle,
  };

  return (
    <AccordionContext.Provider value={value}>
      <div {...props} />
    </AccordionContext.Provider>
  );
}

import { ComponentPropsWithoutRef, FC } from 'react';

import classNames from 'classnames';

const Line: FC<ComponentPropsWithoutRef<'div'>> = ({ className }) => {
  return (
    <div className={classNames(' rounded-full flex-grow h-[1px] bg-primary-600', className)} />
  );
};

export interface HorizontalSeparatorProps extends ComponentPropsWithoutRef<'div'> {
  text?: string;
  lineClassName?: string;
  textClassName?: string;
}

const HorizontalSeparator: FC<HorizontalSeparatorProps> = ({
  children,
  text,
  className,
  lineClassName,
}) => {
  return (
    <div
      className={classNames('w-full flex items-center', { 'gap-3': text || children }, className)}
    >
      <Line className={lineClassName} />

      {!children && text && <div className={classNames('text-neutral-700')}>{text}</div>}

      {children}

      <Line className={lineClassName} />
    </div>
  );
};

export default HorizontalSeparator;

import React, { ComponentPropsWithoutRef } from 'react';

// NOTE:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/noopener
// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/noreferrer
// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel#nofollow
const ExternalLink = (props: ComponentPropsWithoutRef<'a'>) => {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a target="_blank" rel="noreferrer" {...props} />;
};

export default ExternalLink;
